/*
 * Home Styles
 */
.dav-bg-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 450px;

  @media #{$layout-mobile-sm} {
    width: 95%;
  }
}
.dav-home {
  &-logo {
    max-width: 450px;
    padding: 20px 10px 10px;
    img {
      height: 80%;
      width: 200px;
    }
    h1 {
      font-weight: 700;
    }

    p {
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 1.5;
      margin-bottom: 10px;
    }
  }

  &-content {
    padding: 10px 20px;
    max-width: 450px;
  }

  @media #{$layout-tablet} {
    &-logo {
      img {
        width: 280px;
      }
    }

    &-content {
      max-width: 540px;
    }
  }

  @media #{$layout-mobile-sm} {
    &-content {
      max-width: 100%;
      width: 100%;
      padding: 20px;
    }

    &-logo {
      img {
        width: 280px;
      }
    }
  }
}
