.itinary-card {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  width: 100%;

  &-number {
    height: 45px;
    width: auto;
    min-width: 45px;
    border-radius: 50%;
    color: #fafafa;
    background-color: #bdbdbd;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    text-align: center;
  }

  .content {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    margin-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
      font-size: 16px;
      font-weight: 400;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    p {
      color: rgba(0, 0, 0, 0.54);
      font-size: 14px;
      font-size: 16px;
      font-weight: 400;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
