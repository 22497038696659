/**
 * Modal
 */

.dav-modal {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $ff-heading;
    color: $color-secondary;
  }

  h1 {
    font-size: 3.2857rem;
    line-height: 4rem;

    @media #{$layout-tablet} {
      font-size: 2.9857rem;
      line-height: 3.4rem;
    }

    @media #{$layout-mobile-lg} {
      font-size: 2.6857rem;
      line-height: 3.1rem;
    }
  }

  h2 {
    font-size: 2.57rem;
    line-height: 3.2857rem;

    @media #{$layout-tablet} {
      font-size: 2.37rem;
      line-height: 3.0857rem;
    }

    @media #{$layout-mobile-lg} {
      font-size: 2.17rem;
      line-height: 2.857rem;
    }
  }

  h3 {
    font-size: 2rem;
    line-height: 2.7rem;

    @media #{$layout-mobile-lg} {
      font-size: 1.91rem;
      line-height: 2.357rem;
    }
  }

  h4 {
    font-size: 1.71rem;
    line-height: 2.43rem;

    @media #{$layout-mobile-lg} {
      font-size: 1.51rem;
      line-height: 2.13rem;
    }
  }

  h5 {
    font-size: 1.43rem;
    line-height: 2.14rem;

    @media #{$layout-mobile-lg} {
      font-size: 1.23rem;
      line-height: 2.04rem;
    }
  }

  h6 {
    font-size: 1.14rem;
    line-height: 1.857rem;

    @media #{$layout-mobile-lg} {
      font-size: 1.04rem;
      line-height: 1.657rem;
    }
  }
  p {
    margin-bottom: 0;
    font-size: 1.2rem;
  }

  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    z-index: 1000;
  }

  &-container {
    position: relative;
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    padding-top: 30px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    max-height: 90vh;
    overflow-y: auto;

    &-sm {
      width: 400px;
    }

    &-md {
      width: 600px;
    }

    &-lg {
      width: 800px;
    }

    &-xl {
      width: 1000px;
    }

    &-full {
      width: 100%;
    }

    &-close-button {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
      padding: 4px;
      border-radius: 50%;
    }
  }

  &-content {
    padding: 10px 0;
  }

  *::-webkit-scrollbar-track {
    border-radius: 0px;
    background-color: #ffffff;
  }

  *::-webkit-scrollbar {
    width: 8px;
    background-color: $color-secondary;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $color-secondary;
  }
}
