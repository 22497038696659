/*********************
    REFRESH ICON STYLE
     *********************/

@-webkit-keyframes refreshing_icon {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes refreshing_icon {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.refreshing_icon {
  -webkit-animation: refreshing_icon 2s linear infinite;
  -moz-animation: refreshing_icon 2s linear infinite;
  -ms-animation: refreshing_icon 2s linear infinite;
  -o-animation: refreshing_icon 2s linear infinite;
  animation: refreshing_icon 2s linear infinite;
}
