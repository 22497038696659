.seo-mobile-preview {
  border-bottom: 1px hidden rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: rgba(32, 33, 36, 0.28) 0px 1px 6px;
  font-family: Arial, Roboto-Regular, HelveticaNeue, sans-serif;
  max-width: 400px;
  box-sizing: border-box;
  font-size: 14px;
  padding: 12px 16px;
}

.seo-mobile-preview__url {
  display: inline-block;
  cursor: pointer;
  position: relative;
  max-width: 90%;
  white-space: nowrap;
  font-size: 14px;
  vertical-align: top;
}
.seo-mobile-preview__url-inner {
  display: inline-flex;
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  margin-bottom: 12px;
  padding-top: 1px;
  line-height: 20px;
  vertical-align: top;
  align-items: center;
}

.seo-mobile-preview__url-inner img {
  width: 16px;
  height: 16px;
  margin-right: 12px;
  vertical-align: middle;
}

.seo-mobile-preview__url-inner span {
  font-size: 12px;
  color: rgb(60, 64, 67);
}

.seo-mobile-preview__title {
  cursor: pointer;
  position: relative;
}
.seo-mobile-preview__title-inner {
  color: rgb(25, 103, 210);
  text-decoration: none;
  font-size: 16px;
  line-height: 20px;
  font-weight: normal;
  margin: 0px;
  display: inline-block;
  overflow: hidden;
  max-width: 600px;
  vertical-align: top;
  text-overflow: ellipsis;
}

.seo-mobile-preview__title-inner {
  display: inline-block;
  max-height: 40px;
  padding-top: 1px;
  vertical-align: top;
  overflow: hidden;
  text-overflow: ellipsis;
}

.seo-mobile-preview__description {
  color: rgb(60, 64, 67);
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  position: relative;
  max-width: 600px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.seo-mobile-preview__description-image {
  width: 25%;
  padding: 4px;
  border-radius: 8px;
}

.seo-mobile-preview__description-image img {
  width: 100%;
  height: 80px;
  border-radius: 8px;
}

.seo-mobile-preview__description-image.desktop-view img {
  width: 100%;
  height: 100px;
}

.seo-mobile-preview__description-content {
  color: rgb(112, 117, 122);
  width: 75%;
}
