.dav_manage_agent_card_inner {
  padding: 20px;
}

.dav_manage_agent_card_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dav_manage_agent_card_body {
  padding: 20px 0;
}

.dav_manage_agent_card_body_content span {
  color: var(--dav__primary_color);
}
