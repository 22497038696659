.dav-dashboard {
  background-color: aliceblue;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px 20px;
    height: 73px;
    width: 100%;
    position: fixed;
    right: 0;
    z-index: 50;
    @media #{$layout-mobile-lg} {
      padding: 8px 20px;
      height: 80px;
    }

    &-logo {
      width: 100px;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
      }

      &--menu-trigger {
        path,
        svg {
          fill: $color-primary;
          stroke: $color-primary;
        }

        &--active {
          background-color: red;
        }
      }
    }

    &-user {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &__name {
        font-size: 28px;
        font-weight: 600;
        color: $color-secondary;
        margin-bottom: 5px;
      }

      &__avatar {
        width: 54px;
        height: 54px;
        border-radius: 50%;
        background-color: #f5f5f5;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        color: $color-primary;

        &--large {
          width: 100px;
          height: 100px;
        }

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
    }
  }

  &__side-menu {
    background-color: #ffffff;
    color: #fff;
    border-right: 1px solid #e6e6e6;
    height: 100vh;
    width: 250px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 80;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
    transform: translateX(-100%);

    &__large {
      transform: none;
    }
    &--active {
      transform: translateX(0);
    }
    &--hide {
      transform: translateX(-100%);
    }
    &__header {
      background-color: #f5f5f5;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2px 20px;
      border-bottom: 1px solid #e6e6e6;

      &--mobile {
        background-color: aliceblue;
      }

      @media #{$layout-mobile-lg} {
        padding: 8px 10px;
      }

      &-logo {
        width: 100px;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    &__body {
      color: $color-secondary;
      width: 100%;
      height: calc(100vh - 73px);
      overflow-y: auto;
      padding-bottom: 20px;

      @media #{$layout-mobile-lg} {
        height: calc(100vh - 160px);
      }

      &__item {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 10px 20px;
        margin: 10px 0;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        width: 95%;
        transition: all 0.3s ease-in-out;
        background-color: #e5faee;
        color: #008d3b;
        cursor: pointer;

        &__text {
          font-size: 18px;
        }

        &__icon {
          svg {
            width: 24px;
            height: 24px;
          }
        }

        &:hover {
          background-color: $color-primary;
          color: #ffffff;
        }
        &--active {
          background-color: $color-primary;
          color: #ffffff;
        }
        a {
          display: flex;
          align-items: center;
          color: #999;
          text-decoration: none;
          font-size: 16px;
          transition: all 0.3s ease-in-out;
          &:hover {
            color: #000;
          }
          &__icon {
            margin-right: 10px;
            font-size: 20px;
          }
        }
      }
    }
  }

  &__main-content {
    width: 100%;
    transition: all 0.3s ease-in-out;
    overflow: hidden;

    &__large {
      margin-left: 250px;
    }
    &--active {
      margin-left: 0;
    }
  }

  .country-card {
    position: relative;
    display: block;
    background: #fff;
    padding: 8px;
    border-radius: 15px;
    transition: box-shadow 0.2s ease-in-out;
    width: 100%;

    .image {
      width: 100%;
      border-radius: 15px;
      min-height: 120px;

      .country-name {
        border-top-right-radius: 15px;
        border-bottom-left-radius: 15px;
      }

      img {
        width: 100%;
        border-radius: 15px;
        height: auto;
      }
    }

    h4 {
      font-size: 15px;
      margin: 0;
      font-weight: 600;
    }

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
  .email-field {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
