/**
 * Button
 */

.dav-button {
  position: relative;
  padding: 0 30px;
  color: #ffffff;
  border: none;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  z-index: 1;
  text-transform: uppercase;
  font-size: 0.9rem;
  letter-spacing: 2px;
  font-weight: 600;
  height: $input-button-height;
  line-height: $input-button-height;
  box-shadow: var(--dav-ring-shadow, 0 0 #0000), var(--dav-ring-shadow, 0 0 #0000), $dav-shadow-sm;

  &-primary {
    background-color: #588048;

    &:disabled {
      opacity: 0.8;
    }
  }

  &-white {
    background-color: #ffffff;
    color: $color-secondary;

    &:hover {
      color: $color-primary;
    }
  }

  &-outline {
    border: 1px solid $color-primary;
    background-color: transparent;
    box-shadow: none;
    color: $color-primary;

    &:hover {
      background-color: $color-primary;
      color: #ffffff;
    }
  }

  &-danger {
    background-color: #fff0f0;
    color: #ff0000;
    &:hover {
      background-color: #ff0000;
      color: #ffffff;
    }
  }

  &:hover {
    box-shadow: none;
    opacity: 0.9;
  }
  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }

  @media #{$layout-mobile-lg} {
    height: $input-button-height - 5;
    line-height: $input-button-height - 5;
  }
}
