/*
 * Pagination
 */

.dav-pagination {
  ul {
    list-style: none;
    margin-top: -5px;
    margin-left: -5px;
    padding-left: 0;
    margin-bottom: 0;
    display: flex;
  }

  li {
    list-style: none;
    margin-top: 5px;
    margin-left: 5px;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $color-primary;
      height: 40px;
      width: 40px;
      text-align: center;
      color: $color-primary;
      line-height: 38px;
      cursor: pointer;
    }

    &.is-active {
      span {
        background: $color-primary;
        color: #ffffff;
      }
    }

    &.is-disabled {
      cursor: not-allowed;
      span {
        pointer-events: none;
        opacity: 0.6;
      }
    }
  }
}
