/** @format */

// Colors

$color-primary: #5bc787;
$color-secondary: #588048;

$color-body: $color-secondary;
$color-heading: #103b49;
$color-border: #cbced8;
$color-bg-body: #f1f5ff;
$dav-shadow-sm:
  0 1px 3px 0 rgb(0 0 0 / 0.1),
  0 1px 2px -1px rgb(0 0 0 / 0.1);
$dav-shadow-md:
  0 4px 6px -1px rgb(0 0 0 / 0.1),
  0 2px 4px -2px rgb(0 0 0 / 0.1);
$dav-ring-shadow: 0 0 #0000;

// Font Family

$ff-body: 'Poppins', sans-serif;
$ff-heading: 'Merriweather', sans-serif;

// Font Size
$fz-body: 18px;

// Input Button Height
$input-button-height: 50px;
$transition: all 0.4s ease-out;

// Layouts
$layout-dektop: 'only screen and (min-width: 1920px)';
$layout-desktop-xs: 'only screen and (min-width: 1600px) and (max-width: 1919px)';
$layout-laptop: 'only screen and (min-width: 1200px) and (max-width: 1599px)';
$layout-notebook: 'only screen and (min-width: 992px) and (max-width: 1199px)';
$layout-tablet: 'only screen and (min-width: 768px) and (max-width: 991px)';
$layout-mobile-lg: 'only screen and (max-width: 767px)';
$layout-mobile-sm: 'only screen and (max-width: 575px)';
$layout-mobile-xs: 'only screen and (max-width: 480px)';

// Social Colors
$facebook: #3b5999;
$messenger: #0084ff;
$twitter: #55acee;
$google-plus: #dd4b39;
$pinterest: #bd081c;
$linkedin: #0077b5;
$flickr: #ff0084;
$dribbble: #ea4c89;
$google: #dd4b39;
$skype: #1ab7ea;
$behance: #0057ff;
$youtube: #cd201f;
$vimeo: #86c9ef;
$yahoo: #410093;
$paypal: #00588b;
$delicious: #205cc0;
$flattr: #f67c1a;
$android: #a4c639;
$tumblr: #34465d;
$wikipedia: #333333;
$stumbleupon: #eb4924;
$foursquare: #f94877;
$digg: #191919;
$spotify: #81b71a;
$reddit: #ff5700;
$cc: #688527;
$vk: #4c75a3;
$rss: #ee802f;
$podcast: #e4b21b;
$dropbox: #007ee5;
$github: #171515;
$soundcloud: #ff3300;
$wordpress: #21759b;
$yelp: #af0606;
$scribd: #666666;
$cc-stripe: #008cdd;
$drupal: #27537a;
$slideshare: #0077b5;
$get-pocket: #ee4056;
$bitbucket: #0e4984;
$stack-overflow: #ef8236;
$hacker-news: #ff6600;
$xing: #126567;
$instagram: #e4405f;
$quora: #b92b27;
$openid: #e16309;
$steam: #111111;
$amazon: #e47911;
$weibo: #df2029;
$blogger: #f57d00;
$whatsapp: #25d366;
$wechat: #09b83e;
$line: #00c300;
$medium: #02b875;
$vine: #00b489;
$slack: #3aaf85;
$snapchat: #fffc00;
