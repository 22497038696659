.dav__cover_image {
  background-color: #f5f8fa;
  padding: 10px;
  border: 2px dashed #cbd6e2;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  user-select: none;
  width: 100%;
  border-radius: 12px;
}

.dav__cover_image:hover {
  border: 2px dashed #a4b0be;
}
.dav__cover_image p {
  font-family: 'Merriweather', serif;
  font-style: italic;
  padding: 12px;
  text-align: center;
}
.dav__cover_image_content {
  background-color: rgba(0, 0, 0, 0.219);
  color: #fff;
  opacity: 0;
}
.dav__cover_image:hover .dav__cover_image_content {
  opacity: 1;
}
