/**
 * Utilities
 */

.md-wrapper {
  &-content {
    padding: 0px 20px;
    max-width: 600px;
    width: 100%;
  }

  @media #{$layout-mobile-sm} {
    &-content {
      max-width: 100%;
      padding: 0;
    }
  }
}

.dav-section-separator {
  width: 100%;
  padding: 0 70px;
  &-content {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    padding: 4px 12px;
    border-radius: 20px;
    text-transform: uppercase;
    color: $color-primary;
    white-space: nowrap;
    font-weight: 600;
  }
  &-line {
    height: 2px;
    background-color: #ffffff;
    width: 80%;
  }
}

/**
 * Button Loader
 */
.dav-loader {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.dav-loader:after {
  content: ' ';
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: dav-loader 1.2s linear infinite;
}

.dav-loader.button {
  width: 40px;
  height: 40px;
}

.dav-loader.button::after {
  width: 34px;
  height: 34px;
  opacity: 0.8;
  margin: 6px;
}

@keyframes dav-loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/**
* FORMIK ERROR
*/
.invalid-field {
  border-color: #dc3545 !important;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

/**
 * Navigation
 */
.nav-link.active {
  color: $color-primary;
  background-color: rgba(240, 248, 255, 0.54);
}

.dav-welcome-card::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  border-left: 0px;
  border-right: 70px solid #e5faee;
  border-bottom: 70px solid transparent;
  border-top: 0px;
}

/**
  * Package Card
  
  */
.dav-package-card {
  &:hover {
    .dav-package-card-arrow-icon {
      transform: translateX(4px);
      transition: all 0.3s ease-in-out;
    }
  }
}

.dav-package {
  &-image {
    &-zoom-icon {
      font-size: 32px;
    }
    &:hover {
      background-color: #ffffff;
      opacity: 0.8;
      .dav-package-image-zoom-icon {
        transition: all 0.3s ease-in-out;
        transform: scale(2) translate(10px, -10px);
      }
    }
  }
}

.dav-input-field {
  position: relative;
  transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  background-color: rgba(0, 0, 0, 0.09);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
