/**
 * Typography
 */
@import url('https://fonts.googleapis.com/css?family=Merriweather:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Poppins:ital,wght@0,300;0,400;0,500;1,400&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  overflow: hidden;
  overflow-y: auto;
}

:root {
  --dav__primary_color: #5bc787;
  --dav__secondary_color: #f15d30;
  --dav__tertiary_color: #588048;
  --dav__App_bg_dark: #424242;
  --dav__App_bg_sections_light: #fafafa;
  --dav__App_bg_sections_dark: #333;
  --dav__AppBar_bg_dark: #1c1d1f;
  --dav__Text_color_light: #000000de;
  --dav__Text_color_dark: #ffffff;
  --dav__bg_color_hover_dark: #616060dc;
  --dav__footer_bg_dark: #1c1d1f;
  --dav__button_bg_dark: #777777;
  --dav__animation_dark_color: #0bdad07e;
  --dav__highlighted_section_bg_light: #eaeaea;
  --dav__highlighted_section_bg_dark: #2a2f3ad3;
  --dav__dashboard_menu_bg_light: #eaeaea;
  --dav__box_shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%),
    0px 1px 3px 0px rgb(0 0 0 / 12%);
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: $fz-body;
  font-family: $ff-body;
  color: $color-body;
  font-weight: 400;
  background: $color-bg-body;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $ff-heading;
    color: $color-secondary;
  }

  h1 {
    font-size: 3.2857rem;
    line-height: 4rem;

    @media #{$layout-tablet} {
      font-size: 2.9857rem;
      line-height: 3.4rem;
    }

    @media #{$layout-mobile-lg} {
      font-size: 2.6857rem;
      line-height: 3.1rem;
    }
  }

  h2 {
    font-size: 2.57rem;
    line-height: 3.2857rem;

    @media #{$layout-tablet} {
      font-size: 2.37rem;
      line-height: 3.0857rem;
    }

    @media #{$layout-mobile-lg} {
      font-size: 2.17rem;
      line-height: 2.857rem;
    }
  }

  h3 {
    font-size: 2rem;
    line-height: 2.7rem;

    @media #{$layout-mobile-lg} {
      font-size: 1.91rem;
      line-height: 2.357rem;
    }
  }

  h4 {
    font-size: 1.71rem;
    line-height: 2.43rem;

    @media #{$layout-mobile-lg} {
      font-size: 1.51rem;
      line-height: 2.13rem;
    }
  }

  h5 {
    font-size: 1.43rem;
    line-height: 2.14rem;

    @media #{$layout-mobile-lg} {
      font-size: 1.23rem;
      line-height: 2.04rem;
    }
  }

  h6 {
    font-size: 1.14rem;
    line-height: 1.857rem;

    @media #{$layout-mobile-lg} {
      font-size: 1.04rem;
      line-height: 1.657rem;
    }

    p {
      margin-bottom: 0;
      font-size: 1.2rem;
    }
  }

  // TODO: Add these back if you have a slider section using slick slider
  // .slick-slider ul {
  //   display: flex !important;
  //   flex-wrap: wrap !important;
  //   justify-content: center;
  //   gap: 8px;
  //   margin-bottom: 2rem;
  // }

  // .slick-dots li button {
  //   width: 24px;
  //   height: 24px;
  //   font-size: 12px;
  //   background-color: #2e344e !important;
  //   border-radius: 50%;
  //   position: relative;
  //   margin-top: 50px;
  //   top: 40px;
  // }

  // .slick-dots li button:before {
  //   content: "";
  //   width: 12px;
  //   height: 12px;
  //   border-radius: 50%;
  //   background-color: #a4acc4;
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   transform: translate(-50%, -50%);
  //   opacity: 0.25;
  // }

  // .slick-dots li.slick-active button:before {
  //   background: #f9ebe4;
  //   opacity: 1;
  // }
}

img {
  max-width: 100%;
}

@include placeholder-color($color-body);

::-moz-selection {
  background: $color-primary;
  color: #ffffff;
}

::-ms-selection {
  background: $color-primary;
  color: #ffffff;
}

::-o-selection {
  background: $color-primary;
  color: #ffffff;
}

::selection {
  background: $color-primary;
  color: #ffffff;
}

a {
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    outline: none;
  }
}

a,
button {
  cursor: pointer;
}

input,
button,
select,
textarea {
  border-radius: 0.375rem;
  font-family: $ff-body;
  color: $color-body;
  @include placeholder-color($color-body);

  &:focus,
  &:active {
    outline: none;
  }
}

.dav-input-eye {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.dav-button-eye {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  padding: 0.6rem 0.75rem 0.62rem;
}

input,
select,
textarea {
  width: 100%;
  vertical-align: middle;
}

textarea {
  padding: 10px 15px;
}

blockquote {
  border-left: 5px solid $color-primary;
  font-size: 18px;
  line-height: 27px;
  padding: 40px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
  margin-left: 25px;
  background: lighten($color-bg-body, 5);

  p {
    margin-bottom: 0;
    font-style: italic;
    font-size: 1.2rem;
  }

  footer {
    font-size: 14px;

    a {
      color: $color-primary;

      &:hover {
        color: $color-primary;
      }
    }
  }

  @media #{$layout-mobile-lg} {
    font-size: 16px;
    line-height: 25px;
    padding: 20px;
    margin-left: 0;
  }
}

input,
select,
textarea {
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition:
      background-color 50000s ease-in-out 0s,
      color 50000s ease-in-out 0s;
  }
}

/**
    * Modal styles
    */
.modal-open {
  overflow: hidden;
}
.modal-closed {
  overflow: auto;
}
.dav-dashboard-cover-wrapper {
  overflow-y: auto;
}
.modal-open .dav-dashboard-cover-wrapper {
  overflow: hidden;
  height: 100vh;
}

/**
    * React Quill styles
    */

.ql-toolbar.ql-snow {
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  // padding: 8px;
  background: rgba(0, 0, 0, 0.09) !important;
  border: unset;
  padding: 16px 16px 14px 16px !important;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  background-color: #f5f8fa;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  min-height: 240px;
}

.itinary-editor .ql-toolbar.ql-snow + .ql-container.ql-snow {
  background-color: #ffffff;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  z-index: 3 !important;
}

.ql-editor {
  p {
    color: initial;
  }
}
