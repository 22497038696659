.data-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border-radius: 4px;
  margin-bottom: 4px;
  font-size: 14px;
  color: #5e5e5e;
  line-height: 18px;
  background-color: #fff;
  overflow: hidden;
  margin-bottom: 10px;
}

.table-column:hover {
  background-color: aliceblue;
  border-radius: 4px;
}

.data-table th {
  font-size: 14px;
  color: #5e5e5e;
  border-bottom: 1px solid #e0e0e0;
  line-height: 18px;
  padding: 15px 10px 15px 15px;
  white-space: nowrap;
  background-color: #e0e0e0;
}

.data-table tr td {
  border-bottom: 1px solid #e0e0e0;
  line-height: 18px;
  padding: 15px 10px 15px 15px;
  text-align: left;
}

.table-actions-icons {
  outline: transparent solid 2px;
  outline-offset: 2px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-shrink: 0;
  width: 2rem;
  height: 2rem;
  border-radius: 0.375rem;
  transition-property: var(--lazy-transition-property-common);
  transition-duration: var(--lazy-transition-duration-normal);
  background-color: rgba(0, 0, 0, 0.06);
}
.table-actions-icons:hover {
  background-color: rgba(0, 0, 0, 0.03);
}

.no-results-wrapper {
  padding: 20px;
  border-radius: 4px;
  margin-bottom: 4px;
}

.table-wrapper {
  width: 100%;
  overflow-x: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.table-wrapper::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.table-wrapper {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
