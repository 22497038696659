.tour_card_wrapper {
  background-color: #fff;
  box-shadow: 0 1px 3px rgb(0 0 0 / 9%);
  position: relative;
}

.tour_card_header {
  padding: 5px 5px 0;
}
.tour_card_header_inner {
  background-color: var(--dav__primary_color);
  width: 100%;
  max-height: 220px;
  height: 220px;
  position: relative;
}
.tour_card_header_inner img {
  width: 100%;
  max-height: 220px;
  height: 220px;
  position: relative;
}

.tour_card_header.tour_card_header_loading {
  background-color: transparent;
}

.tour_card_header a:hover {
  color: #555;
}

.tour_card_body {
  padding: 14px;
  padding-bottom: 80px;
}
.tour_card_body h4 {
  margin-top: 0;
  font-size: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.4;
  color: #333;
}
.tour_card_body h4 a {
  color: #333;
}

.tour__date {
  text-transform: uppercase;
  color: #666;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  text-align: left;
}
.tour__description {
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color: #555;
  font-size: 16px;
}
.tour__footer {
  height: 40px;
  position: absolute;
  bottom: 4px;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.tour__footer.tour_footer_loading {
  position: relative;
}
.tour_link {
  float: left;
  width: 45%;
  position: relative;
  height: 40px;
  line-height: 40px;
  padding-left: 8px;
}

.tour__price {
  background-color: var(--dav__primary_color);
  border-radius: 30px 0 0 30px;
  margin: 10px 0;
  transition: all 0.3s ease-in-out;
  padding-left: 2px;
}
.tour__price_inner {
  width: 100%;
  height: 100%;
  padding: 6px 10px;
  color: #ffffff;
  font-weight: 600;
  border-left: 4px solid #fff;
  border-radius: 30px 0 0 30px;
  background-color: var(--dav__primary_color);
  transition: all 0.3s ease-in-out;
}

.tour_card_wrapper:hover .tour__price_inner {
  padding-left: 24px;
}
