.welcome-screen {
  position: relative;
  border: 1px solid #c3c4c7;
  box-shadow: 0 1px 1px rgb(0 0 0 / 4%);
  background: #fff;
  font-size: 13px;
  line-height: 1.7;
  margin: 16px 0;
}

.section-wrapper {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 20px 0px 20px 0px;
}

.user-image {
  border: 5px solid #ffffff;
  border-radius: 50%;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}
